/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  {
    
    description:
      'Class Assembly 1A',
    href : '/class-assembly-1a-2023'
 
  },
  {
    
    description:
      'Class Assembly 1B',
    href : '/class-assembly-1b-2023'
 
  },
  {
    
    description:
      'Class Assembly 1C',
    href : '/class-assembly-1c-2023'
 
  },
  {
    
    description:
      'Class Assembly 1D',
    href : '/class-assembly-1d-2023'
 
  },
  {
    
    description:
      'Class Assembly 1E',
    href : '/class-assembly-1e-2023'
 
  },
  {
    
    description:
      'Class Assembly 2A',
    href : '/class-assembly-2a-2023'
 
  },
  {
    
    description:
      'Class Assembly 2B',
    href : '/class-assembly-2b-2023'
 
  },
  {
    
    description:
      'Class Assembly 2C',
    href : '/class-assembly-2c-2023'
 
  },
  {
    
    description:
      'Class Assembly 2D',
    href : '/class-assembly-2d-2023'
 
  },
  {
    
    description:
      'Class Assembly 2E',
    href : '/class-assembly-2e-2023'
 
  },
  {
    
    description:
      'Class Assembly 3A',
    href : '/class-assembly-3a-2023'
 
  },
  {
    
    description:
      'Class Assembly 3B',
    href : '/class-assembly-3b-2023'
 
  },
  {
    
    description:
      'Class Assembly 3C',
    href : '/class-assembly-3c-2023'
 
  },
  {
    
    description:
      'Class Assembly 3D',
    href : '/class-assembly-3d-2023'
 
  },
  {
    
    description:
      'Class Assembly 3E',
    href : '/class-assembly-3e-2023'
 
  },
  {
    
    description:
      'Class Assembly 4A',
    href : '/class-assembly-4a-2023'
 
  },
  {
    
    description:
      'Class Assembly 4B',
    href : '/class-assembly-4b-2023'
 
  },
  {
    
    description:
      'Class Assembly 4C',
    href : '/class-assembly-4c-2023'
 
  },
  {
    
    description:
      'Class Assembly 4D',
    href : '/class-assembly-4d-2023'
 
  },
  {
    
    description:
      'Class Assembly 4E',
    href : '/class-assembly-4e-2023'
 
  },
  {
    
    description:
      'Class Assembly 4F',
    href : '/class-assembly-4f-2023'
 
  },
  {
    
    description:
      'Class Assembly 5A',
    href : '/class-assembly-5a-2023'
 
  },
  {
    
    description:
      'Class Assembly 5B',
    href : '/class-assembly-5b-2023'
 
  },
  {
    
    description:
      'Class Assembly 5C',
    href : '/class-assembly-5c-2023'
 
  },
  {
    
    description:
      'Class Assembly 5D',
    href : '/class-assembly-5d-2023'
 
  },
  {
    
    description:
      'Class Assembly 5E',
    href : '/class-assembly-5e-2023'
 
  },
  {
    
    description:
      'Class Assembly 6A',
    href : '/class-assembly-6a-2023'
 
  },
  {
    
    description:
      'Class Assembly 6B',
    href : '/class-assembly-6b-2023'
 
  },
  {
    
    description:
      'Class Assembly 6C',
    href : '/class-assembly-6c-2023'
 
  },
  {
    
    description:
      'Class Assembly 6D',
    href : '/class-assembly-6d-2023'
 
  },
  {
    
    description:
      'Class Assembly 6E',
    href : '/class-assembly-6e-2023'
 
  },
  {
    
    description:
      'Class Assembly 7A',
    href : '/class-assembly-7a-2023'
 
  },

  {
    
    description:
      'Class Assembly 7B',
    href : '/class-assembly-7b-2023'
 
  },
  {
    
    description:
      'Class Assembly 7C',
    href : '/class-assembly-7c-2023'
 
  },
  {
    
    description:
      'Class Assembly 7D',
    href : '/class-assembly-7d-2023'
 
  },
  {
    
    description:
      'Class Assembly 7E',
    href : '/class-assembly-7e-2023'
 
  },
  {
    
    description:
      'Class Assembly 8A',
    href : '/class-assembly-8a-2023'

  },
  {
    
    description:
      'Class Assembly 8B',
    href : '/class-assembly-8b-2023'
 
  },
  {
    
    description:
      'Class Assembly 8C',
    href : '/class-assembly-8c-2023'
 
  },
  {
    
    description:
      'Class Assembly 8D',
    href : '/class-assembly-8d-2023'
 
  },
  {
    
    description:
      'Class Assembly 8E',
    href : '/class-assembly-8e-2023'
 
  },
  {
    
    description:
      'Class Assembly 9A',
    href : '/class-assembly-9a-2023'
 
  },
  {
    
    description:
      'Class Assembly 9B',
    href : '/class-assembly-9b-2023'
 
  },
  {
    
    description:
      'Class Assembly 9C',
    href : '/class-assembly-9c-2023'
 
  },
  {
    
    description:
      'Class Assembly 9D',
    href : '/class-assembly-9d-2023'
 
  },
  {
    
    description:
      'Class Assembly 9E',
    href : '/class-assembly-9e-2023'
 
  },
  {
    
    description:
      'Class Assembly 10A',
    href : '/class-assembly-10a-2023'
 
  },
  {
    
    description:
      'Class Assembly 10B',
    href : '/class-assembly-10b-2023'
 
  },
  {
    
    description:
      'Class Assembly 10C',
    href : '/class-assembly-10c-2023'
 
  },
  {
    
    description:
      'Class Assembly 10D',
    href : '/class-assembly-10d-2023'
 
  },
  {
    
    description:
      'Class Assembly 10E',
    href : '/class-assembly-10e-2023'
 
  },
  {
    
    description:
      'Class Assembly 11A',
    href : '/class-assembly-11a-2023'
 
  },
  {
    
    description:
      'Class Assembly 11B',
    href : '/class-assembly-11b-2023'
 
  },
  {
    
    description:
      'Class Assembly 11C',
    href : '/class-assembly-11c-2023'
 
  },
  {
    
    description:
      'Class Assembly 12A',
    href : '/class-assembly-12a-2023'
 
  },
  {
    
    description:
      'Class Assembly 12B',
    href : '/class-assembly-12b-2023'
 
  },
  {
    
    description:
      'Class Assembly 12C',
    href : '/class-assembly-12c-2023'
 
  },

];

const ClassAssembly2023 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Class Assembly 2023
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ClassAssembly2023;
